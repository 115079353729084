import React, { useEffect } from "react";

import Slider3 from "../slider/Slider3";
import "./stylesHome.css";
import "./slider-inf.css";
import "./StyleServices.css";
import { useInView } from "react-intersection-observer";
import CardInfoHome from "./CardInfoHome";
import CardServices from "./CardServices";
import Estrella from "../common/Estrella";

export default function Home() {
  const { ref: refTitle1, inView: inViewTitle1 } = useInView({
    threshold: 0.25,
  });
  const slidesInfo = [
    {
      title: "CITY TOUR",
      description:
        "Descubre los sitios arqueológicos más impresionantes de la ciudad, desde la imponente templo de Santo Domingo hasta el mágico Qorikancha.",
      textButton: "Más información",
      linkPath: "/tour-tradicional/citytour",
      colortexto:"primary-50",
      textcolorbutton:"primary-50",
      bgButton:"secondary-400",
    },
    {
      title: "VALLE SAGRADO",
      description:
        "Situado a los pies de los Andes, el valle que rodea Cuzco tuvo gran importancia para los incas, que lo sembraron de palacios, templos y fortalezas que hoy son Patrimonio Mundial",
      textButton: "Ver más",
      linkPath: "/tour-tradicional/vallesagrado",
      colortexto:"primary-50",
      textcolorbutton:"secondary-50",
      bgButton:"secondary-800",
    },
    {
      title: "AVENTURA MARAS",
      description:
        "¡Acompáñanos en esta singular aventura de medio día! Visitaremos las terrazas experimentales de Moray y las salineras de Maras",
      textButton: "Ver más",
      linkPath: "/tour-tradicional/cuatrimoto-maras-moray",
      colortexto:"primary-50",
      textcolorbutton:"primary-50",
      bgButton:"secondary-400",
    },
    {
      title: "TREK MONTAÑIA",
      description:
        "Este atractivo turístico estuvo rodeado de hielo hace no muchos años. Desde el 2016, este lugar recibe a cientos de visitantes al día convirtiéndose, en uno de los lugares más visitados en Cusco",
      textButton: "Ver más",
      linkPath: "/tour-tradicional/montania-colores",
      colortexto:"primary-50",
      textcolorbutton:"primary-50",
      bgButton:"secondary-800",
    },
  ];

  useEffect(() => {
    document.title = "DONALD'S - INICIO";
  }, []);
  return (
    <>
      <Slider3 slidesInfo={slidesInfo} />
      <div className="o_products_container_home">
        <div className="o_products_container-content">
          <div className="o_card2_title_home">
            <h2 className="lg:my-8 mt-3 titulos_principal">TOUR DIARIOS</h2>
          </div>

          <div className="container-home-cards">
            <CardServices
              title={"CITY TOUR"}
              image={`${process.env.PUBLIC_URL}/images/citytour.png`}
              link={"/tour-tradicional/citytour"}
              output={false}
            />
            <CardServices
              title={"MARAS - MORAY"}
              image={`${process.env.PUBLIC_URL}/images/moray.png`}
              link={"/tour-tradicional/maras-moray"}
              output={false}
            />
            <CardServices
              title={"VALLE SUR"}
              image={`${process.env.PUBLIC_URL}/images/valle_sur.png`}
              link={"/tour-tradicional/valle-sur"}
              output={false}
            />
          </div>
        </div>


        <div className="o_products_container-content">
          <div className="o_card2_title_home">
            <h2 className="lg:my-8 mt-3 titulos_principal">TOUR FULL DAY</h2>
          </div>

          <div className="container-home-cards">
            <CardServices
              title={"VALLE SAGRADO"}
              image={`${process.env.PUBLIC_URL}/images/valle-sagrado-3.png`}
              link={"/tour-tradicional/valle-sagrado"}
              output={false}
            />
            <CardServices
              title={"VALLE VIP"}
              image={`${process.env.PUBLIC_URL}/images/valle-sagrado-home.png`}
              link={"/tour-tradicional/valle-vip"}
              output={false}
            />
            <CardServices
              title={"LAGUNA HUAMANTAY"}
              image={`${process.env.PUBLIC_URL}/images/laguna_huamantay.png`}
              link={"/tour-tradicional/laguna-huamantay"}
              output={true}
            />
            <CardServices
              title={"MONTAÑA 7 COLORES"}
              image={`${process.env.PUBLIC_URL}/images/montania_colores.png`}
              link={"/tour-tradicional/montania-colores"}
              output={true}
            />
            <CardServices
              title={"WAQRAPUKARA"}
              image={`${process.env.PUBLIC_URL}/images/Waqrapukara.png`}
              link={"/"}
              output={true}
            />
            <CardServices
              title={"MONTAÑA PALCOYO"}
              image={`${process.env.PUBLIC_URL}/images/montania-palcoyo.png`}
              link={"/"}
              output={true}
            />
          </div>
        </div>

        <div className="o_products_container-content">
          <div className="o_card2_title_home">
            <h2 className="lg:my-8 mt-3 titulos_principal">TOUR AVENTURA</h2>
          </div>

          <div className="container-home-cards-2">
            <CardServices
              title={"CUATRIMOTOS MARAS - MORAY"}
              image={`${process.env.PUBLIC_URL}/images/home-cuatri-maras.png`}
              link={"/tour-tradicional/valle-sagrado"}
              output={false}
            />
            <CardServices
              title={"CUATRIMOTO MORADA DE DIOSES"}
              image={`${process.env.PUBLIC_URL}/images/home-morada.png`}
              link={"/"}
              output={false}
            />
          </div>
        </div>

      </div>

      <div className="o_products_container_home">
        <div className="o_products_container-content">
          <div className="o_card2_title_home">
            <h2 className="lg:my-8 my-3 titulos_principal">
              TE OFRECEMOS
            </h2>
          </div>

          <div className="w-full h-full flex gap-8">
            <div className="flex-1 mr-8">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1">
                <Estrella
                  textNumber={"01"}
                  icon={"/images/estrella_azul.png"}
                  title={"Experiencia y conocimientos"}
                  description={
                    "Nos enorgullecemos de ofrecer experiencias de viaje inolvidables y personalizadas para nuestros clientes. Con más de 10 años de experiencia, somos líderes en la creación de itinerarios únicos que satisfacen las necesidades y deseos de cada viajero."
                  }
                />
                <Estrella
                  textNumber={"02"}
                  icon={"/images/estrella_fucsia.png"}
                  title={"Comodidad y conveniencia"}
                  description={
                    "Entendemos que la comodidad y la conveniencia son fundamentales para disfrutar al máximo de tus aventuras. Nos esforzamos por ofrecerte una experiencia de viaje sin preocupaciones, donde cada detalle se ha cuidado meticulosamente para garantizar tu tranquilidad y disfrute."
                  }
                />
                <Estrella
                  textNumber={"03"}
                  icon={"/images/estrella_naranja.png"}
                  title={"Seguridad y protección"}
                  description={
                    "Nos dedicamos a proporcionarte experiencias de viaje inolvidables con la máxima tranquilidad y protección. Con una trayectoria de más de 10 años, hemos perfeccionado cada detalle para garantizar que tus viajes sean seguros, protegidos y libres de preocupaciones."
                  }
                />
                <Estrella
                  textNumber={"04"}
                  icon={"/images/estrella_verde.png"}
                  title={"Asistencia y apoyo durante el viaje"}
                  description={
                    "nos esforzamos por hacer que tus viajes sean completamente libres de preocupaciones. Desde la planificación inicial hasta tu regreso a casa, nos encargamos de todos los detalles para que puedas relajarte y disfrutar al máximo de tu experiencia de viaje."
                  }
                />
                <Estrella
                  textNumber={"05"}
                  icon={"/images/estrella_turquesa.png"}
                  title={"Personalización y atención al cliente"}
                  description={
                    "Creemos que cada viaje debería ser único y adaptado a tus deseos y necesidades individuales. Nos enorgullece ofrecer un servicio altamente personalizado y una atención al cliente excepcional para garantizar que cada experiencia de viaje sea verdaderamente inolvidable."
                  }
                />

                
              </div>
            </div>
            <div className="flex-1 hidden lg:block ">
              <div className="w-full h-full">
                <div
                  className="h-full w-full bg-no-repeat bg-cover bg-cente rounded-3xl"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL
                    }${"/images/principios_donald.jpg"})`,
                  }}
                ></div>
              </div>
            </div>
          </div>

        </div> 
      </div>



      <div className="o_products_container_home">
        <div className="o_products_container-content">
          <div className="o_card2_title_home">
            <h2 className="lg:my-8 my-2 titulos_principal">EMPRESAS QUE NOS RESPALDAN</h2>
          </div>
          <div className="w-full h-full grid  grid-cols-2 lg:grid-cols-4 gap-8 px-0 sm:px-8 my-2 flex-wrap mt-2">
                  <CardInfoHome
                  image={"/images/mincetur.png"} 
                  link={"https://www.mincetur.gob.pe/accesible/"}
                  />
                  <CardInfoHome
                  image={"/images/perurail.png"} 
                  link={"https://www.perurail.com/"}
                  />
                  <CardInfoHome
                  image={"/images/incarail.png"} 
                  link={"https://incarail.com/es"}
                  output={""} 
                  />
                  <CardInfoHome
                  image={"/images/dircetur.png"} 
                  link={"https://gercetur.regioncusco.gob.pe/"}
                  output={""} 
                  />
          </div>
        </div>
      </div>
      
    </>
  );
}
