import Molde from "./MoldeTourTradicional";
import CardLugar from "../common/card/CardLugar";
import CardRecomienda from "../common/card/CardRecomienda";
import CardCaracteristica from "../common/card/CardCaracteristica";
import React from 'react';


export default function ValleSur() {
  return (
    <>
      <Molde
        image={"/images/valle-sur-portada.png"}
        title={"VALLE SUR"}
        subtitle={"(Medio Día)"}
        description={
          "El Valle Sur del Cusco es la denominación turística que reciben los atractivos turísticos localizados al sureste de la ciudad del Cuzco. Los más importantes son: Tipón, Piquillacta, la iglesia de San Pedro de Andahuaylillas, los humedales de Huasao y sus decenas de pueblos llenos de historia y tradiciones. Estos destinos se pueden visitar con un tour todo incluido o por propia cuenta."
        }
        linkForm={"/reservar/citytour-dia"}
        tipoprecio={"Precio por persona"}
        monto={"US$ 15.00"}
        moneda={"DOLARES"}
        picture={
            <>
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/valle-sur-1.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/valle-sur-2.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/valle-sur-3.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/valle-sur-4.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/valle-sur-5.png`} alt="" />
            </>
          }
        incluye={
          <>
            <p>Transporte turistico</p>
            <p>Guia profesional</p>
            <p>Asistencia permanente</p>
          </>
        }
        noIncluye={
          <>
            <p>Boleto turistico</p>
            <p>Gastos extra</p>
          </>
        }
        caracteristica={
            <>
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/level.svg`}
                title={"Dificultad"}
                desc={"Facil"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/compartido.svg`}
                title={"Tipo Servicio"}
                desc={"Compartido"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/duracion.svg`}
                title={"Duración"}
                desc={"5 Horas"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/cultural.svg`}
                title={"Tipo de Tour"}
                desc={"Cultural"}
              />
            </>
          }

        lugarvisita={
          <>
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Tipon"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Pikillaqta "}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Andahuaylillas "}
            />
            
          </>
        }
        TourRecomienda={
          <>
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"MONTAÑA 7 COLORES"}
              puntuacion={"4.5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"VALLE VIP"}
              puntuacion={"5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"LAGUNA HUAMANTAY"}
              puntuacion={"5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"valle-sur"}
              puntuacion={"4.5"}
            />
          </>
        }
      />
    </>
  );
}
