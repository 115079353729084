import { useState, React } from "react";
import LogoTurismo from "../../assets/logoTurismo";
import ContainerItemsDefault from "./ContainerItemsDefault";
import "./stylesNavbar.css";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { BsFacebook, BsInstagram, BsTiktok, BsWhatsapp } from "react-icons/bs";

export default function Navbar1() {
  const [idItem, setIdItem] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(null); // Estado para controlar qué submenú está abierto
  const [subSubMenuOpen, setSubSubMenuOpen] = useState(null);

  const toggleSubMenu = (index) => {
    setSubMenuOpen(subMenuOpen === index ? null : index);
  };

  const toggleSubSudMenu=(index)=>{
    setSubSubMenuOpen(subSubMenuOpen===index?null:index);
  };
  return (
    <>
      <header className="o_footer_container">
        <nav className="o_navbar_container">
          <div className="flex items-start justify-start">
            <LogoTurismo className="w-full  sm:w-full h-14 lg:w-[240px] lg:h-[70px]" />
          </div>
          <div className="o_navbar_container_a">
            <Link
              to={"/nuestra-historia/nosotros"}
              className="o_navbar_container_header"
            >
              <div className="o_navbar_container_a__lb"><p>¿QUIÉNES SOMOS?</p></div>
            </Link>

            <div className="o_productos">
              <div className="o_navbar_container_a__lb" onMouseEnter={() => setIdItem(1)}><p>TOUR DIARIO</p></div>
            </div>
            <div className="o_navbar__menu">
              <div className="w-full max-w-7x1 bg-primary-50 mx-auto rounded-xl relative h-96">
                <ContainerItemsDefault id={idItem} />
              </div>
            </div>

            <div className="o_productos">
              <div className="o_navbar_container_a__lb" onMouseEnter={() => setIdItem(2)}><p>TOURS PROGRAMA</p> </div>
            </div>
            <div className="o_navbar__menu">
              <div className="w-full max-w-7x1 bg-primary-50 mx-auto rounded-xl relative h-96">
                <ContainerItemsDefault id={idItem} />
              </div>
            </div>

            <div className="o_productos">
              <div className="o_navbar_container_a__lb" onMouseEnter={() => setIdItem(3)}><p>CAMINO INCA - TREK</p></div>
            </div>
            <div className="o_navbar__menu">
              <div className="w-full max-w-7x1 bg-primary-50 mx-auto rounded-xl relative h-96">
                <ContainerItemsDefault id={idItem} />
              </div>
            </div>

            <Link
              to={"#"}
              className="o_navbar_container_header"
            >
              <div className="o_navbar_container_a__lb"><p>PROMOCIONES</p></div>
            </Link>

            <Link
              to={"#"}
              className="o_navbar_container_header"
            >
              <div className="o_navbar_container_a__lb"><p>EXPERIENCIAS</p></div>
            </Link>
            
          </div>
          <div className="md:hidden cursor-pointer">
            <GiHamburgerMenu
              className="w-[32px] h-[32px] text-primary-800"
              onClick={() => setIsOpen(true)}
            />
          </div>
        </nav>
        {isOpen && (
        <nav className="bg-gray-400 bg-opacity-50 w-full fixed z-[60] top-0 left-0 bottom-0">
            <div className="fixed z-[60] top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-primary-50 border-r overflow-y-auto bg-opacity-100">
            <div className='flex items-center justify-between relative mb-4'>
              <div className="w-40"><LogoTurismo className={"h-40"} /></div>
              <button className="navbar-close" onClick={() => setIsOpen(false)}>
                <AiOutlineClose className="w-[25px] h-[25px] text-primary-800" />
              </button>
            </div>
            <div className={`navbar-menu ${isOpen ? 'active' : ''}`}>


              <ul className="navbar-items">


                <li className="navbar-item" onClick={() => toggleSubMenu(0)}>TOUR DIARIO</li>
                {subMenuOpen === 0 && (
                  <ul className="sub-menu">
                    <li className="navbar-subitem" onClick={()=>toggleSubSudMenu(0)}>TOUR MEDIO DIA</li>
                    {subSubMenuOpen===0&&(
                      <ul>
                        <li className='navbar_sub_submenu'>
                          <Link Link to={"/tour-tradicional/citytour"} onClick={()=>setIsOpen(false)}>
                          CITY TOUR</Link></li>
                        <li className='navbar_sub_submenu'>
                          <Link Link to={"/tour-tradicional/maras-moray"} onClick={()=>setIsOpen(false)}>
                          MARAS - MORAY</Link> </li>
                        <li className='navbar_sub_submenu'>
                          <Link Link to={"/tour-tradicional/valle-sur"} onClick={()=>setIsOpen(false)}>
                          VALLE SUR</Link></li>
                      </ul>
                    )}
                    <li className="navbar-subitem" onClick={()=>toggleSubSudMenu(1)}>TOUR FULL DAY</li>
                    {subSubMenuOpen===1&&(
                      <ul>
                        <li className='navbar_sub_submenu'>
                          <Link to={"/tour-tradicional/vallesagrado"} onClick={() => setIsOpen(false)}>
                          VALLE SAGRADO
                          </Link>
                        </li>
                        <li className='navbar_sub_submenu'>
                          <Link to={"/tour-tradicional/vallevip"} onClick={()=>setIsOpen(false)}>
                          VALLE VIP</Link>
                        </li>
                      </ul>
                    )}
                    <li className="navbar-subitem" onClick={()=>toggleSubSudMenu(2)}>TOUR TREK</li>
                    {subSubMenuOpen===2&&(
                      <ul>
                        <li className='navbar_sub_submenu'>
                          <Link to={"/tour-tradicional/laguna-huamantay"} onClick={() => setIsOpen(false)}>
                            LAGUNA HUAMANTAY
                          </Link>
                        </li>
                        <li className='navbar_sub_submenu'>
                          <Link to={"/tour-tradicional/montania-colores"}onClick={() => setIsOpen(false)}>
                            MONTAÑA 7 COLORES
                          </Link>
                        </li>
                        <li className='navbar_sub_submenu'>
                          <Link to={"#"}onClick={() => setIsOpen(false)}>
                            MONTAÑA PALCOYO
                          </Link>
                        </li>
                        <li className='navbar_sub_submenu'>
                          <Link to={"#"}onClick={() => setIsOpen(false)}>
                            WAQRAPUKARA
                          </Link>
                        </li>
                    </ul>
                    )}
                    <li className="navbar-subitem" onClick={()=>toggleSubSudMenu(3)}>TOUR AVENTURA</li>
                    {subSubMenuOpen===3&&(
                      <ul>
                        <li className='navbar_sub_submenu'>
                          <Link to={"/tour-tradicional/cuatrimoto-maras-moray"} onClick={() => setIsOpen(false)}>
                            CUATRIMOTOS MARAS - MORAY
                          </Link>
                        </li>
                        <li className='navbar_sub_submenu'>
                          <Link to={"#"}onClick={() => setIsOpen(false)}>
                            CUATRIMOTOS MORADA DE DIOSES
                          </Link>
                        </li>
                    </ul>
                    )}
                  </ul>
                )}

              <li className="navbar-item" onClick={() => toggleSubMenu(1)}>TOUR PROGRAMA</li>
                {subMenuOpen === 1 && (
                  
                  <ul className="sub-menu">
                    <li className="navbar-subitem" onClick={()=>toggleSubSudMenu(1)}>PROGRAMA CUSCO</li>
                    {subSubMenuOpen===1&&(
                      <ul>
                        <li className='navbar_sub_submenu'>
                          <Link Link to={"#"} onClick={()=>setIsOpen(false)}>
                          CUSCO TRADICIONAL 4D/3N</Link></li>
                        <li className='navbar_sub_submenu'>
                          <Link Link to={"#"} onClick={()=>setIsOpen(false)}>
                          CUSCO CULTURAL 5D/4N</Link> </li>
                        <li className='navbar_sub_submenu'>
                          <Link Link to={"#"} onClick={()=>setIsOpen(false)}>
                          CUSCO INCAICO 5D/4N</Link></li>
                        <li className='navbar_sub_submenu'>
                          <Link Link to={"#"} onClick={()=>setIsOpen(false)}>
                          CUSCO MULTICOLOR 7D/6N</Link></li>
                        <li className='navbar_sub_submenu'>
                          <Link Link to={"#"} onClick={()=>setIsOpen(false)}>
                          CUSCO MAGICO 8D/7N</Link></li>  
                      </ul>
                    )}
                    <li className="navbar-subitem" onClick={()=>toggleSubSudMenu(2)}>PROGRAMA ESPECIAL</li>
                    {subSubMenuOpen===2&&(
                      <ul>
                        <li className='navbar_sub_submenu'>
                          <Link to={"#"} onClick={() => setIsOpen(false)}>
                          CUSCO - PUNO 7D/6N
                          </Link>
                        </li>
                        <li className='navbar_sub_submenu'>
                          <Link to={"#"} onClick={()=>setIsOpen(false)}>
                          CUSCO - AREQUIPA 6D/5N</Link>
                        </li>
                        <li className='navbar_sub_submenu'>
                          <Link to={"#"} onClick={()=>setIsOpen(false)}>
                          CUSCO - PUNO - AREQUIPA 11D/10N</Link>
                        </li>
                      </ul>
                    )}
                    <li className="navbar-subitem" onClick={()=>toggleSubSudMenu(3)}>PROGRAMA PERUANO</li>
                    {subSubMenuOpen===3&&(
                      <ul>
                        <li className='navbar_sub_submenu'>
                          <Link to={"#"} onClick={() => setIsOpen(false)}>
                          MACHUPICCHU 7D/6N
                          </Link>
                        </li>
                        <li className='navbar_sub_submenu'>
                          <Link to={"#"}onClick={() => setIsOpen(false)}>
                          MACHUPICCHU 6D/5N
                          </Link>
                        </li>
                        <li className='navbar_sub_submenu'>
                          <Link to={"#"}onClick={() => setIsOpen(false)}>
                          MACHUPICCHU 11D/10N
                          </Link>
                        </li>
                    </ul>
                    )}
                  </ul> 
                )}

<li className="navbar-item" onClick={() => toggleSubMenu(2)}>CAMINO INCA - TREK</li>
                {subMenuOpen === 2 && (
                  
                  <ul className="sub-menu">
                    <li className="navbar-subitem" onClick={()=>toggleSubSudMenu(1)}>CAMINO INCA</li>
                    {subSubMenuOpen===1&&(
                      <ul>
                        <li className='navbar_sub_submenu'>
                          <Link Link to={"#"} onClick={()=>setIsOpen(false)}>
                          CAMINO INCA 2D/1N</Link></li>
                        <li className='navbar_sub_submenu'>
                          <Link Link to={"#"} onClick={()=>setIsOpen(false)}>
                          CAMINO INCA 4D/3N</Link> </li>
                      </ul>
                    )}
                    <li className="navbar-subitem" onClick={()=>toggleSubSudMenu(2)}>PROGRAMA ESPECIAL</li>
                    {subSubMenuOpen===2&&(
                      <ul>
                        <li className='navbar_sub_submenu'>
                          <Link to={"#"} onClick={() => setIsOpen(false)}>
                          SALKANTAY TREK 4D/3N
                          </Link>
                        </li>
                        <li className='navbar_sub_submenu'>
                          <Link to={"#"} onClick={()=>setIsOpen(false)}>
                          SALKANTAY TREK 4D/3N</Link>
                        </li>
                        <li className='navbar_sub_submenu'>
                          <Link to={"#"} onClick={()=>setIsOpen(false)}>
                          CHOQUEQUIRAO 3D/2N</Link>
                        </li>
                        <li className='navbar_sub_submenu'>
                          <Link to={"#"} onClick={()=>setIsOpen(false)}>
                          CHOQUEQUIRAO 4D/3N</Link>
                        </li>
                      </ul>
                    )}
                  </ul> 
                )}

                <li className="navbar-item">
                  <Link
                    to={"/nuestra-historia/nosotros"}
                    onClick={() => setIsOpen(false)}>
                        ¿Quienes Somos?
                  </Link>
                </li>
                <li className="navbar-item">
                  <Link
                    to={"#"}
                    onClick={() => setIsOpen(false)}
                  >
                    Promociones
                  </Link>
                </li>
                <li className="navbar-item">
                  <Link 
                    to ={"#"}
                    onClick={()=>setIsOpen(false)}
                  >Experiencias
                  </Link>
                </li>
              </ul>
            </div>

            <div className="mt-auto w-full">
                  <div className="grid grid-cols-4 mx-auto justify-items-center">
                    <Link
                      to={"https://web.facebook.com/donaldstourcusco"}
                      target="_blank"
                      preventScrollReset={true}
                    >
                      <BsFacebook className="w-6 h-6 sm:w-10 sm:h-10 text-primary-800 hover:text-primary-600" />
                    </Link>
                    <Link
                      to={"https://wa.link/s5zlcs"}
                      target="_blank"
                      preventScrollReset={true}
                    >
                      <BsWhatsapp className="w-6 h-6 sm:w-10 sm:h-10 text-primary-800 hover:text-primary-600" />
                    </Link>
                    <Link
                      to={"https://www.instagram.com/donaldstourcusco/"}
                      target="_blank"
                      preventScrollReset={true}
                    >
                      <BsInstagram className="w-6 h-6 sm:w-10 sm:h-10 text-primary-800 hover:text-primary-600" />
                    </Link>
                    <Link
                      to={"https://www.tiktok.com/@donaldstourscusco"}
                      target="_blank"
                      preventScrollReset={true}
                    >
                      <BsTiktok className="w-6 h-6 sm:w-10 sm:h-10 text-primary-800 hover:text-primary-600" />
                    </Link>
                    
                  </div>
                  <div className="pt-6"></div>
                  <p className="my-4 text-xs text-center text-primary-800">
                    <span>DONALDS TOUR OPERADOR CUSCO - Copyright © 2024</span>
                  </p>
                </div>
          </div>
        </nav>
        )}
      </header>
    </>
  );
}
