import { Link } from "react-router-dom";
import LogoDileBlack from "../../assets/LogoTurismoBlack";
import { BsFacebook, BsInstagram, BsTiktok, BsWhatsapp } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import React from 'react';

export default function Footer1() {
  return (
    <footer className="w-full bg-primary-800 h-full mt-16">
      <div className="w-full max-w-7xl mx-auto px-0 lg:px-10 flex flex-col lg:flex-row justify-between space-y-4 lg:space-y-0">
        <div className="w-full lg:w-4/12 lg:space-y-4 flex flex-col lg:flex-col justify-between  lg:justify-start">
          <div className="w-full lg:w-[100%] px-0 ">
            <LogoDileBlack className={"lg:w-full w-[50%]"} />
          </div>
          <div className="sm:w-[250px] lg:w-full flex items-center justify-between px-16">
            <button className="h-6 w-6 lg:w-8 lg:h-8 items-center justify-center align-center rounded-full mr-2">
              <Link
                to={"https://web.facebook.com/donaldstourcusco"}
                target="_blank"
                preventScrollReset={true}
              >
                <BsFacebook className="w-full h-full text-primary-50  hover:scale-125" />
              </Link>
            </button>
            <button className="h-6 w-6 lg:w-8 lg:h-8 items-center justify-center align-center rounded-full mr-2">
              <Link
                to={"https://wa.link/s5zlcs"}
                target="_blank"
                preventScrollReset={true}
              >
                <BsWhatsapp className="w-full h-full text-primary-50  hover:hover:scale-125" />
              </Link>
            </button>
            <button className="h-6 w-6 lg:w-8 lg:h-8 items-center justify-center align-center rounded-full mr-2">
              <Link
                to={"https://www.instagram.com/donaldstourcusco/"}
                target="_blank"
                preventScrollReset={true}
              >
                <BsInstagram className="w-full h-full text-primary-50  hover:hover:scale-125" />
              </Link>
            </button>
            <button className="h-6 w-6 lg:w-8 lg:h-8 items-center justify-center align-center rounded-full mr-2">
              <Link
                to={"https://www.tiktok.com/@donaldstourscusco"}
                target="_blank"
                preventScrollReset={true}
              >
                <BsTiktok className="w-full h-full text-primary-50 hover:scale-125" />
              </Link>
            </button>
          </div>
        </div>


        <div className="w-full lg:w-8/12 flex-1 flex flex-col sm:flex-row space-y-8 sm:space-y-0 pt-2 lg:pt-8">
          <div className="w-full sm:w-1/2 px-8">
            <div className="row-span-2 ">
              <div>
                <h3 className="text-sm font-bold text-primary-50 uppercase flex items-center space-x-2">
                  <p>Contactanos</p>
                </h3>

                <div className="flex flex-col items-start mt-1 space-y-1">
                  <Link
                    to={"/"}
                    target="_blank"
                    preventScrollReset={true}
                    className="text-primary-50 text-[12px] font-light hover:font-bold"
                  >
                    Direccion: Calle Queswa S/N
                  </Link>
                  <Link
                    to={"/"}
                    target="_blank"
                    preventScrollReset={true}
                    className="text-primary-50 text-[12px] font-light hover:font-bold"
                  >
                    Celular: +51 964287432/ +51 968791037
                  </Link>
                  <Link
                    to={"/"}
                    target="_blank"
                    preventScrollReset={true}
                    className="text-primary-50 text-[12px] font-light hover:font-bold"
                  >
                    Correo: info@donaldstour.com
                  </Link>                  
                </div>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/2 px-8 hidden lg:block">
            <div className="">
              <div>
                <h3 className="text-sm font-bold text-primary-50 uppercase">
                  Enlaces de interes
                </h3>

                <div className="flex flex-col items-start mt-1 space-y-1">
                <Link
                    to={"/nuestra-historia/nosotros"}
                    preventScrollReset={true}
                    className="text-primary-50 text-[12px] font-light hover:font-bold"
                  >
                    ¿Quines Somos?
                  </Link>
                  <Link
                    to={"/tour-tradicional/citytour"}
                    target="_blank"
                    preventScrollReset={true}
                    className="text-primary-50 text-[12px] font-light hover:font-bold"
                  >
                    City tour
                  </Link>
                  <Link
                    to={"/tour-tradicional/valle-sagrado"}
                    target="_blank"
                    preventScrollReset={true}
                    className="text-primary-50 text-[12px] font-light hover:font-bold"
                  >
                    Valle Sagrado
                  </Link>
                  <Link
                    to={"/tour-tradicional/montania-colores"}
                    target="_blank"
                    preventScrollReset={true}
                    className="text-primary-50 text-[12px] font-light hover:font-bold"
                  >
                    Montañas de colores
                  </Link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="mt-8">
        <p className="text-[10px] text-center">
          <span className="font-bold text-primary-50">DONALDS TOUR OPERADOR CUSCO - Copyright © 2024</span>
        </p>
      </div>
    
    </footer>
    
  );
}
