import { Link } from "react-router-dom";
import "./stylesMolde.css";
import React,{ useEffect } from "react";

export default function MoldeTourTradicional({
  image,
  picture,
  caracteristica,
  title,
  lugarvisita,
  incluye,
  noIncluye,
  TourRecomienda,
  tipoprecio,
  monto,
  moneda,
  description,
  subtitle,
  linkForm,
  
}) {
  useEffect(() => {
    document.title = `DONALDS - ${title}`;
  }, [title]);


  return (
    <>
      <div className="mb-2">
        <div className="relative">
          <div
            className={`pagadiario-container`}
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}${image})`,
            }}
          ></div>

          <div className="absolute w-full h-full top-0 ">
            <div className="w-full h-full mx-auto max-w-7xl grip content-end lg:px-16 px-4">
              <h1 className="text-[32px] mb-4 md:text-[48px] lg:text-[64px] font-bold text-primary-50">
                {title}
              </h1>
              <h6 className="text-primary-50 font-light lg:text-[30px] -mt-6 mb-2">{subtitle}</h6>
            </div>
          </div>
        </div>
      </div>

        <div className="relative mb-20 w-full h-full top-0 left-0 p-2">

          <div className="w-full mx-auto max-w-7xl xl:h-full flex flex-col lg:flex-row">


              <div className="w-full xl:w-1/5 bg-primary-50 h-full xl:h-full xl:px-2">
                <div className="flex flex-col h-full w-full ">
                  <div className="mt-4">
                    <span className="font-semibold">{tipoprecio}</span>
                    <h1 className="text-primary-800 font-bold text-[45px] -my-4">{monto}</h1>
                    <span className="font-semibold">{moneda}</span>
                  </div>
                  <div className="btn-servicio mt-6 items-center">
                    <Link to={"https://api.whatsapp.com/send?phone=51964287432&text=Estoy%20Interesado%20en%20los%20Tours%20que%20Ofrece"} 
                    preventScrollReset={true}>

                      <button className={"text-primary-50 bg-primary-800 p-2 w-full font-light text-[18px] hover:font-semibold"}>
                            Más información
                          </button>
                    </Link>
                  </div>
                  <div className="btn-servicio mt-2 items-center">
                    <Link to={"https://api.whatsapp.com/send?phone=51964287432&text=Estoy%20Interesado%20en%20los%20Tours%20que%20Ofrece"} 
                    preventScrollReset={true}>

                      <button className={"text-primary-50 bg-primary-800 p-2 w-full font-light text-[18px] hover:font-semibold"}>
                            Reservar
                          </button>
                    </Link>
                  </div>

                    <div className="mt-6 mb-2">
                      <span className="font-semibold ">lugares a visitar:</span>
                    </div>

                    {lugarvisita}

                    <div className="mt-6 mb-2">
                      <span className="font-semibold ">Incluye:</span>
                    </div>
                    <div className="rounded-l border-primary-800 border-solid border-2">
                      <div className="m-2">
                        {incluye}
                      </div>
                    </div>

                    <div className="mt-6 mb-2">
                      <span className="font-semibold ">No incluye:</span>
                    </div>
                    <div className="rounded-l border-primary-800 border-solid border-2">
                      <div className="m-2">
                        {noIncluye}
                      </div>
                    </div>
                </div>
              </div>



              <div className="w-full xl:w-4/5 pb-4 xl:h-full lg:px-0">
                <h1 className="m-4 font-bold text-[16px] lg:text-[24px] text-primary-800">{title} {subtitle}</h1>
                <div className="relative w-full h-full xl:w-full xl:h-full flex flex-col lg:m-4 ">
                  
                  <div className="w-full lg:w-full ">
                  
                    <div className="flex lg:flex-row flex-col gap-4 lg:w-full justify-center items-center p-2 rounded-lg bg-primary-800">

                      {caracteristica}

                    </div>

                    <div className="border-2 border-primary-800 mt-6 rounded-lg">
                      <p className="p-4 text-justify">{description}</p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 lg:grid-cols-5 p-2 lg:gap-4 gap-2">
                      {picture}
                    </div>


                    <div className=" w-full flex lg:flex-row flex-col items-center lg:justify-around lg:gap-8 gap-2 border-2 bg-gray-200 border-gray-800 mt-6 rounded-lg"> 
                      <div class="inline-flex h-full lg:w-[200px] w-full items-center justify-start lg:p-0 p-4">
                          <div class="w-1/5 text-gray-800 font-bold mr-4">
                            <img
                              src={`${process.env.PUBLIC_URL}/icons/email.svg`}
                              className="lg:w-20 lg:h-20 w-10 h-10"/>
                          </div>
                          <div class="w-full text-primary-800 font-bold ">
                            <div className="inline-grid grid-cols-1 text-[#000]">
                              <span className="font-light">Escribenos</span>
                              <span className="font-bold">info@cuscodonaldstour.com</span>
                            </div>
                          </div>
                        </div>

                        <div class=" inline-flex h-full lg:w-[200px] w-full items-center justify-start lg:p-0 p-4 ">
                          <div class="w-1/5 text-gray-800 font-bold mr-4">
                          <img
                            src={`${process.env.PUBLIC_URL}/icons/celular_do.svg`}
                            className="lg:w-20 lg:h-20 w-10 h-10"
                          />
                          </div>
                          <div class="w-[120px] font-bold ">
                            <div className="inline-grid grid-cols-1 text-[#000] ">
                              <span className="font-light">Llamanos</span>
                              <span className="font-bold">+51 968791037</span>
                            </div>
                          </div>
                        </div>
                    </div>


                    <div className=" w-full h-full flex justify-end relative">
                      <div className="w-full">
                        <div className="m-2 ">
                          <h1 className="font-bold lg:text-[24px] text-[16px] text-primary-800">TOUR RECOMENDADOS</h1>
                        </div>
                        <div className="w-[100%] flex justify-center">
                          <div className="w-[100%] flex  px-2 justify-center">
                            <div className="w-[100%] mt-0 grid gap-2 grid-cols-2 lg:grid-cols-4 lg:gap-4">
                              {TourRecomienda}
                            </div>
                          </div>
                        </div>
                      </div>



                  </div>
                </div>
              </div>
            </div>  

          </div>
        </div>
 

    </>
  );
}
