import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import React from 'react';

export default function CardServices({title,image,link,output}){

    const {ref: refCustom, useInView:inView1}=useInView({
        threshold: 0.25,
    });
    return link ?(
        output ? (
            <Link ref={refCustom}
            className='card rounded-xl'
            to={link}
            preventScrollReset={true}
        >
            
                <img className='img_card' src={image}/>
                <div class="card__content rounded-xl">
                    <p class="card__title">{title}</p>
                </div>
            
        </Link>
        ):(
            <Link ref={refCustom}
            className='card rounded-xl'
            to={link}
            preventScrollReset={true}
        >
            
                <img className='img_card' src={image} />
                <div class="card__content rounded-xl">
                    <p class="card__title">{title}</p>
                </div>
        </Link>
        )
      ):(
        <Link ref={refCustom}
            className='card rounded-xl'
            to={link}
            preventScrollReset={true}
        >
            
                <img className='img_card' src={image} />
                <div class="card__content rounded-xl">
                    <p class="card__title">{title}</p>
                </div>
            
        </Link>
      );
}