export default function Estrella({ textNumber, icon, title, description }) {
  return (
    <>
      <div className="flex m-2">
        <div>
          <div className="relative w-16 h-16 lg:w-20 lg:h-20">
            <img
              className="w-16 h-16 lg:w-20 lg:h-20"
              src={`${process.env.PUBLIC_URL}${icon}`}
              alt="estrella"
            />
            <div className="absolute z-30 top-0 left-0 w-full h-full">
              <div className="w-full h-full flex justify-center items-center">
                <p className="font-bold text-[28px] text-white">
                  {textNumber}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="pl-4 lg:block flex items-center justify-center lg:items-start lg:justify-start">
          <h3 className="font-bold text-[14px] lg:text-[18px] text-primary-800">{title}</h3>
          <p className="font-light hidden lg:block text-[14px] lg:text-[18px] text-justify">{description}</p>
        </div>
      </div>
    </>
  );
}
