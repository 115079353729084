import Molde from "./MoldeTourTradicional";
import CardLugar from "../common/card/CardLugar";
import CardRecomienda from "../common/card/CardRecomienda";
import CardCaracteristica from "../common/card/CardCaracteristica";
import React from 'react';


export default function MarasMoray() {
  return (
    <>
      <Molde
        image={"/images/img-slide-desktop-moray.png"}
        title={"MARAS - MORAY"}
        subtitle={"(Medio Día)"}
        description={
          "Este recorrido presenta algunos de los sitios incas más interesantes de Cusco. El recorrido comienza con una visita al pueblo colonial de Maras y su iglesia. Desde Maras continuamos hacia el cercano sitio inca de Moray, una magnífica serie de terrazas circulares excavadas en el suelo que alguna vez fueron utilizadas por los incas para experimentar con cultivos. Nuestro destino final son las minas de sal que han estado en uso desde la época Pre-Inca. El agua de un arroyo subterráneo se canaliza a través de estanques donde se evapora y deja cristales de sal pura. Con miles de piscinas blancas que se extienden por el valle, las vistas son impresionantes."
        }
        linkForm={"/reservar/citytour-dia"}
        tipoprecio={"Precio por persona"}
        monto={"US$ 15.00"}
        moneda={"DOLARES"}
        picture={
            <>
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/maras-moray-1.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/maras-moray-2.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/valle-vip-3.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/maras-moray-4.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/maras-moray-5.png`} alt="" />
            </>
          }
        incluye={
          <>
            <p>Transporte turistico</p>
            <p>Guia profesional</p>
            <p>Asistencia permanente</p>
          </>
        }
        noIncluye={
          <>
            <p>Boleto turistico</p>
            <p>Ingreso a las salineras</p>
            <p>Gastos extra</p>
          </>
        }
        caracteristica={
            <>
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/level.svg`}
                title={"Dificultad"}
                desc={"Facil"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/compartido.svg`}
                title={"Tipo Servicio"}
                desc={"Compartido"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/duracion.svg`}
                title={"Duración"}
                desc={"Full day"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/cultural.svg`}
                title={"Tipo de Tour"}
                desc={"Cultural - trek"}
              />
            </>
          }

        lugarvisita={
          <>
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Maras"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Moray"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Salineras"}
            />
            
          </>
        }
        TourRecomienda={
          <>
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"MONTAÑA 7 COLORES"}
              puntuacion={"4.5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"VALLE VIP"}
              puntuacion={"5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"LAGUNA HUAMANTAY"}
              puntuacion={"5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"MARAS-MORAY"}
              puntuacion={"4.5"}
            />
          </>
        }
      />
    </>
  );
}
