import Molde from "./MoldeTourTradicional";
import CardLugar from "../common/card/CardLugar";
import CardRecomienda from "../common/card/CardRecomienda";
import CardCaracteristica from "../common/card/CardCaracteristica";
import React from 'react';


export default function CuatriMarasMoray() {
  return (
    <>
      <Molde
        image={"/images/cuatri-maras-moray-portada.png"}
        title={"CUATRIMOTOS"}
        subtitle={"(MARAS - MORAY)"}
        description={
          "¡Acompáñanos en esta singular aventura de medio día! Visitaremos las terrazas experimentales de Moray y las salineras de Maras en un recorrido lleno de belleza natural, cultura y mucha emoción en Cuatrimotos. Además, podremos observar los magníficos Nevados de Verónica y Chicón."
        }
        linkForm={"/reservar/citytour-dia"}
        tipoprecio={"Precio por persona"}
        monto={"US$ 35.00"}
        moneda={"DOLARES"}
        picture={
            <>
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/cuatri-maras-moray-1.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/cuatri-maras-moray-2.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/cuatri-maras-moray-3.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/cuatri-maras-moray-4.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/cuatri-maras-moray-5.png`} alt="" />
            </>
          }
        incluye={
          <>
            <p>Transporte turistico</p>
            <p>Guia profesional</p>
            <p>Asistencia permanente</p>
            <p>Cuatrimotos</p>
            <p>Casco para Gopro</p>
            <p>Botiquin</p>
            <p>Guantes</p>
          </>
        }
        noIncluye={
          <>
            <p>Boleto turistico</p>
            <p>Gastos extra</p>
          </>
        }
        caracteristica={
            <>
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/level.svg`}
                title={"Dificultad"}
                desc={"Media"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/compartido.svg`}
                title={"Tipo Servicio"}
                desc={"Compartido"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/duracion.svg`}
                title={"Duración"}
                desc={"5 Horas"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/cultural.svg`}
                title={"Tipo de Tour"}
                desc={"Aventura"}
              />
            </>
          }

        lugarvisita={
          <>
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Cruz pata"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Moray"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Salineras "}
            />
            
          </>
        }
        TourRecomienda={
          <>
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"MONTAÑA 7 COLORES"}
              puntuacion={"4.5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"VALLE VIP"}
              puntuacion={"5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"LAGUNA HUAMANTAY"}
              puntuacion={"5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"cuatri-maras-moray"}
              puntuacion={"4.5"}
            />
          </>
        }
      />
    </>
  );
}
