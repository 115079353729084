import { BrowserRouter, Routes, Route } from "react-router-dom";
/*PAGINAS POR TOUR - MEDIO DIA*/
import Citytour from "./components/tour-tradicional/Citytour";
import MarasMoray from "./components/tour-tradicional/MarasMoray";
import ValleSur from "./components/tour-tradicional/ValleSur";
/**FIN PAGINAS POR TOUR MEDIO DIA */
/**PAGINAS TOUR TRADICIONAL - FULL DAY */
import ValleSagrado from "./components/tour-tradicional/ValleSagrado";
import ValleVip from "./components/tour-tradicional/ValleVip";
/** FIN PAGINAS POR TOUR TRADICIONAL - FULL DAY */
/**PAGINAS TREK FULL DAY */
import MontaniaColores from "./components/tour-tradicional/MontaniaColores";
import LagunaHuamantay from "./components/tour-tradicional/LagunaHuamantay";
/**FIN PAGINAS TREK FULL DAY */
/**PAGINAS TOUR AVENTURA */
import CuatriMarasMoray from "./components/tour-tradicional/CuatriMarasMoray";
/**FIN PAGINAS TOUR AVENTURA */
/** FORMULARIOS DE TOUR */

/** FIN FORMULARIOS DE TOUR */

import ScrollTop from "./components/common/ScrollTop";

import Home from "./components/home/Home";

import Nosotros from "./components/home/Nosotros";

import CustomPage from "./components/common/CustomPage";


function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <>
          <ScrollTop />

          <Routes>
            
            <Route
              exact
              path="/"
              element={<CustomPage component={<Home />} />}
            />
            /**MEDIO DIA */
            <Route
            exact
            path="/tour-tradicional/citytour"
            element={<CustomPage component={<Citytour/>}/>}
            />
            <Route
            exact
            path="/tour-tradicional/maras-moray"
            element={<CustomPage component={<MarasMoray/>}/>}
            />
            <Route
            exact path="/tour-tradicional/valle-sur"
            element={<CustomPage component={<ValleSur/>}/>}
            />
            /** FULL DAY */
            <Route
            exact path="/tour-tradicional/vallesagrado"
            element={<CustomPage component={<ValleSagrado/>}/>}
            />
            <Route
            exact
            path="/tour-tradicional/vallevip"
            element={<CustomPage component={<ValleVip/>}/>}
            />
            /**TOUR TREK */
            <Route exact path="/tour-tradicional/montania-colores"
            element={<CustomPage component={<MontaniaColores/>}/>}
            />
            <Route exact path="/tour-tradicional/laguna-huamantay"
            element={<CustomPage component={<LagunaHuamantay/>}/>}
            />
            /** TOUR AVENTURA */
            <Route
            exact
            path="/tour-tradicional/cuatrimoto-maras-moray"
            element={<CustomPage component={<CuatriMarasMoray/>}/>}
            />


            <Route
              exact
              path="/nuestra-historia/nosotros"
              element={<CustomPage component={<Nosotros />} />}
            />
           
            <Route
              path="*"
              element={
                <div>
                  <p>Error 404</p>
                </div>
              }
            />
          </Routes>
        </>
      </BrowserRouter>
    </>
  );
}

export default App;
