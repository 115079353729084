import Molde from "./MoldeTourTradicional";
import CardLugar from "../common/card/CardLugar";
import CardRecomienda from "../common/card/CardRecomienda";
import CardCaracteristica from "../common/card/CardCaracteristica";
import React from 'react';


export default function LagunaHuamantay() {
  return (
    <>
      <Molde
        image={"/images/img-slide-desktop-laguna.png"}
        title={"LAGUNA HUAMANTAY"}
        subtitle={"(FULL DAY)"}
        description={
          "El impresionante Lago Humantay, está rodeado de imponentes glaciares que forman parte de la Cordillera de los Andes, entre estos el majestuoso Nevado Salkantay y Humantay, al deshielo del nevado da origen a la esplendida laguna Humantay, tan solo verlo transforma tu forma de ver la naturaleza . La Laguna Humantay se encuentra a 4,300 m.s.n.m. El lago es un lugar sagrado para los lugareños que gracias a los riachuelos que sale de la laguna de humantay realizaban sus buenas cosechas de sus productos, cada año los lugareños se reúnen y brindan sus ofrendas a la Pacha mama (Madre Tirra), para que le vaya bien en sus cosechas de sus productos."
        }
        linkForm={"/reservar/citytour-dia"}
        tipoprecio={"Precio por persona"}
        monto={"US$ 45.00"}
        moneda={"DOLARES"}
        picture={
            <>
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/laguna-huamantay-1.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/laguna-huamantay-2.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/laguna-huamantay-3.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/laguna-huamantay-4.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/laguna-huamantay-5.png`} alt="" />
            </>
          }
        incluye={
          <>
            <p>Recojo en hotel</p>
            <p>Transporte turistico</p>
            <p>Guia profesional</p>
            <p>Asistencia permanente</p>
            <p>Desayuno</p>
            <p>Almuerzo</p>
            <p>Botiquin</p>
          </>
        }
        noIncluye={
          <>
            <p>Ingreso a la laguna</p>
            <p>Gastos extra</p>
            <p>Alquiler de caballo</p>
          </>
        }
        caracteristica={
            <>
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/level.svg`}
                title={"Dificultad"}
                desc={"Medio"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/compartido.svg`}
                title={"Tipo Servicio"}
                desc={"Compartido"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/duracion.svg`}
                title={"Duración"}
                desc={"Full day"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/cultural.svg`}
                title={"Tipo de Tour"}
                desc={"trek"}
              />
            </>
          }

        lugarvisita={
          <>
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Mollepata"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Soraypampa"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Salkantay"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Humantay"}
            />
          </>
        }
        TourRecomienda={
          <>
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"MONTAÑA 7 COLORES"}
              puntuacion={"4.5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"VALLE VIP"}
              puntuacion={"5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"LAGUNA HUAMANTAY"}
              puntuacion={"5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"MARAS-MORAY"}
              puntuacion={"4.5"}
            />
          </>
        }
      />
    </>
  );
}
