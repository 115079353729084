import Containeritems from "./ContainerItems";
import React from 'react';

export default function ContainerItemsDefault({ id }) {
  const tourtradicional = [
    {
      src: (
        <img
          src={`${process.env.PUBLIC_URL}/images/nav_city.png`}
          className="w-[100%] rounded-lg"
        />
      ),
      title: "TOUR MEDIO DIA",

      tour1:"CITY TOUR - MAÑANA",
      linktour1:"/tour-tradicional/citytour",
      tour2:"MARAS - MORAY",
      linktour2:"/tour-tradicional/maras-moray",
      tour3:"VALLE SUR",
      linktour3:"/tour-tradicional/valle-sur"

    },
    {
      src: (
        <img
          src={`${process.env.PUBLIC_URL}/images/nav_valle.png`}
          className="w-[100%] rounded-lg"
        />
      ),
      title: "TOUR FULL DAY",
      tour1:"VALLE SAGRADO",
      linktour1:"/tour-tradicional/vallesagrado",
      tour2:"VALLE VIP",
      linktour2:"/tour-tradicional/vallevip",
    },
    {
      src: (
        <img
          src={`${process.env.PUBLIC_URL}/images/nav_laguna.png`}
          className="w-[100%] rounded-lg"
        />
      ),
      title: "TOUR TREK",
      tour1:"LAGUNA HUAMANTAY",
      linktour1:"/tour-tradicional/laguna-huamantay",
      tour2:"MONTAÑA 7 COLORES",
      linktour2:"/tour-tradicional/montania-colores",
      tour3:"MONTAÑA PALCOYO",
      tour4:"WAKRAPUKARA",

    },
    {
      src: (
        <img
          src={`${process.env.PUBLIC_URL}/images/nav_aventura.png`}
          className="w-[100%] rounded-lg"
        />
      ),
      title: "TOUR AVENTURA",
      tour1:"CUATRIMOTOS MARAS MORAY",
      linktour1:"/tour-tradicional/cuatrimoto-maras-moray",
      tour2:"CUATRIMOTOS MORADA DE DIOSES",
    },

  ];

  const tourmachupicchu = [
    {
      src: (
        <img
          src={`${process.env.PUBLIC_URL}/images/nav_pro_cusco.png`}
          className="w-[100%] h-[140px] rounded-lg "
        />
      ),
      title: "PROGRAMA CUSCO",
      tour1:"CUSCO TRADICIONAL 4D/3N",
      tour2:"CUSCO CULTURAL 5D/4N",
      tour3:"CUSCO INCAICO 5D/4N",
      tour4:"CUSCO MULTICOLOR 7D/6N",
      tour5:"CUSCO MAGICO 8D/7N",
    },
    {
      src: (
        <img
          src={`${process.env.PUBLIC_URL}/images/nav_especial.png`}
          className="w-[100%] h-[140px] rounded-lg "
        />
      ),
      title: "PROGRAMA ESPECIAL",
      tour1:"CUSCO - PUNO 7D/6N",
      tour2:"CUSCO - AREQUIPA 6D/5N",
      tour3:"CUSCO - PUNO - AREQUIPA 11D/10N",
    },
    {
      src: (
        <img
          src={`${process.env.PUBLIC_URL}/images/nav_pro_peruano.png`}
          className="w-[100%] h-[140px] rounded-lg "
        />
      ),
      title: "PROGRAMA PERUANO",
      tour1:"MACHUPICCHU 7D/6N",
      tour2:"MACHUPICCHU 6D/5N",
      tour3:"MACHUPICCHU 11D/10N",
    },
  ];
  const touralternativo = [
    {
      src: (
        <img
          src={`${process.env.PUBLIC_URL}/images/nav_camino_inca.png`}
          className="w-[100%] h-[140px] rounded-lg "
        />
      ),
      title: "CAMINO INCA",
      tour1:"CAMINO INCA 2D/1N",
      tour2:"CAMINO INCA 4D/3N",
    },
    {
      src: (
        <img
          src={`${process.env.PUBLIC_URL}/images/nav_trek.png`}
          className="w-[100%] h-[140px] rounded-lg "
        />
      ),
      title: "TREKING",
      tour1:"SALKANTAY TREK 4D/3N",
      tour2:"SALKANTAY TREK 4D/3N",
      tour3:"CHOQUEQUIRAO 3D/2N",
      tour4:"CHOQUEQUIRAO 4D/3N",
    },
  ];

  return (
    <>
      {id === 1 ? <Containeritems icons={tourtradicional} pack={4}/> : <></>}
      {id === 2 ? <Containeritems icons={tourmachupicchu} pack={3}/> : <></>}
      {id === 3 ? <Containeritems icons={touralternativo} pack={2}/> : <></>}
    </>
  );
}
