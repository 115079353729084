import Molde from "./MoldeTourTradicional";
import CardLugar from "../common/card/CardLugar";
import CardRecomienda from "../common/card/CardRecomienda";
import CardCaracteristica from "../common/card/CardCaracteristica";
import React from 'react';


export default function Citytour() {
  return (
    <>
      <Molde
        image={"/images/city_tour.jpg"}
        title={"CITY TOUR"}
        subtitle={"(Mañana)"}
        description={
          "Este Fabuloso Tour De Medio Día Lo Lleva Por Todos Los Sitios Más Importantes De Cusco. Incluyendo, El Templo Del Sol (Qoricancha) Y Los Diversos Sitios Incas Que Se Encuentran Fuera De La Ciudad, Puede Ver Una Increíble Mezcla De Estilo Inca Y Español Y, En El Caso De Qoricancha, Los Dos Combinados. Cusco Es Verdaderamente Un Lugar Fascinante Para Visitar; Brindando Una Mezcla De Las Dos Culturas (Inca Y Española), La Ciudad Tiene Una Apariencia Que Es Completamente Diferente Al Resto Del Perú. Este Recorrido Es Imprescindible Cuando Se Visita Cusco Y Suele Ser Uno De Los Primeros Puertos De Escala Para Todos Los Turistas Visitantes."
        }
        linkForm={"/reservar/citytour-dia"}
        tipoprecio={"Precio por persona"}
        monto={"US$ 11"}
        moneda={"DOLARES"}
        picture={
          <>

            <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/city-tour-1.png`} alt="" />
            <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/city-tour-2.png`} alt="" />
            <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/city-tour-3.png`} alt="" />
            <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/city-tour-4.png`} alt="" />
            <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/city-tour-5.png`} alt="" />
          </>
        }
        incluye={
          <>
            <p>Transporte turistico</p>
            <p>Guia profesional</p>
            <p>Asistencia permanente</p>
          </>
        }
        noIncluye={
          <>
            <p>Ingreso a koricancha </p>
            <p>Boleto turistico </p>
            <p>Gastos extra</p>
          </>
        }
        caracteristica={
          <>
            <CardCaracteristica
              icon={`${process.env.PUBLIC_URL}/icons/level.svg`}
              title={"Dificultad"}
              desc={"Facil"}
            />
            <CardCaracteristica
              icon={`${process.env.PUBLIC_URL}/icons/compartido.svg`}
              title={"Tipo Servicio"}
              desc={"Compartido"}
            />
            <CardCaracteristica
              icon={`${process.env.PUBLIC_URL}/icons/duracion.svg`}
              title={"Duración"}
              desc={"4 Horas"}
            />
            <CardCaracteristica
              icon={`${process.env.PUBLIC_URL}/icons/cultural.svg`}
              title={"Tipo de Tour"}
              desc={"Cultural"}
            />
          </>
        }
        lugarvisita={
          <>
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Qoricancha"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Sacsayhuaman"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Qenqo"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Tambomachay"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Puka pukara"}
            />
          </>
        }
        TourRecomienda={
          <>
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"MONTAÑA 7 COLORES"}
              puntuacion={"4.5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"VALLE VIP"}
              puntuacion={"5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"LAGUNA HUAMANTAY"}
              puntuacion={"5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"MARAS-MORAY"}
              puntuacion={"4.5"}
            />
          </>
        }
      />
    </>
  );
}
