import Molde from "./MoldeTourTradicional";
import CardLugar from "../common/card/CardLugar";
import CardRecomienda from "../common/card/CardRecomienda";
import CardCaracteristica from "../common/card/CardCaracteristica";
import React from 'react';


export default function ValleVip() {
  return (
    <>
      <Molde
        image={"/images/valle-vip-portada.png"}
        title={"VALLE VIP"}
        subtitle={"(FULL DAY)"}
        description={
          "El tour del Valle VIP es uno de los tours más populares de la región. El Valle Sagrado de los Incas fue un área crucial para el imperio Inca y funcionó como un centro agrícola, espiritual y político para ellos. A lo largo de su recorrido, se pueden encontrar muchos sitios incas y preincas y el recorrido del Valle Sagrado, visitaremos los principales sitios arqueologicos como, Chincheros, Moray, las salineras de Maras, Ollantaytambo, Urubamba y Pisac. Con una duración de un día completo, podrá apreciar los finos trabajos de artesanía que produjeron los incas y ver el hermoso paisaje del Valle Sagrado. Muchos turistas optan por hacer este recorrido justo antes de su visita a Machu Picchu"
        }
        linkForm={"/reservar/citytour-dia"}
        tipoprecio={"Precio por persona"}
        monto={"US$ 35.00"}
        moneda={"DOLARES"}
        picture={
            <>
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/valle-vip-1.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/valle-vip-2.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/valle-vip-3.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/valle-vip-4.png`} alt="" />
              <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/valle-vip-5.png`} alt="" />
            </>
          }
        incluye={
          <>
            <p>Transporte turistico</p>
            <p>Guia profesional</p>
            <p>Asistencia permanente</p>
            <p>Almuerzo</p>
          </>
        }
        noIncluye={
          <>
            <p>Boleto turistico</p>
            <p>Ingreso a las salineras</p>
            <p>Gastos extra</p>
            <p>Desayuno</p>
            <p>Cena</p>
          </>
        }
        caracteristica={
            <>
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/level.svg`}
                title={"Dificultad"}
                desc={"Facil"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/compartido.svg`}
                title={"Tipo Servicio"}
                desc={"Compartido"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/duracion.svg`}
                title={"Duración"}
                desc={"Full day"}
              />
              <CardCaracteristica
                icon={`${process.env.PUBLIC_URL}/icons/cultural.svg`}
                title={"Tipo de Tour"}
                desc={"Cultural - trek"}
              />
            </>
          }

        lugarvisita={
          <>
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Chincheros"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Moray"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Salineras"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Ollantaytambo"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Urubamba"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Pisaq"}
            />
          </>
        }
        TourRecomienda={
          <>
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"MONTAÑA 7 COLORES"}
              puntuacion={"4.5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"VALLE VIP"}
              puntuacion={"5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"LAGUNA HUAMANTAY"}
              puntuacion={"5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"MARAS-MORAY"}
              puntuacion={"4.5"}
            />
          </>
        }
      />
    </>
  );
}
