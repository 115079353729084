import Molde from "./MoldeTourTradicional";
import CardLugar from "../common/card/CardLugar";
import CardRecomienda from "../common/card/CardRecomienda";
import CardCaracteristica from "../common/card/CardCaracteristica";
import React from 'react';


export default function MontaniaColores() {
  return (
    <>
      <Molde
        image={"/images/montania-7-colores-portada.png"}
        title={"MONTAÑA DE 7 COLORES"}
        subtitle={"(FULL DAY)"}
        description={
          "La montaña de los 7 Colores (también llamado Vinicunca o simplemente ‘montaña arcoíris’) es una de las nuevas y mejores atracciones del Perú. Ubicada a más de 100 kilómetros de la ciudad del Cusco, en una cumbre altitudinal situada a 5,200 metros sobre el nivel del mar (m.s.n.m.). Se trata de una formación montañosa teñida de varias tonalidades producto de la compleja combinación de minerales. Las laderas y la cumbre están teñidas de diversas tonos que incluyen el rojo, morado, verde, amarillo, rosado y otras variaciones. Este atractivo turístico estuvo rodeado de hielo hace no muchos años. Desde el 2016, este lugar recibe a cientos de visitantes al día convirtiéndose, junto con Machu Picchu, en uno de los lugares más visitados en Cusco-Perú. A continuación toda la información sobre la Montaña de 7 Colores."
        }
        linkForm={"/reservar/citytour-dia"}
        tipoprecio={"Precio por persona"}
        monto={"US$ 40.00"}
        moneda={"DOLARES"}
        picture={
          <>

            <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/city-tour-1.png`} alt="" />
            <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/city-tour-2.png`} alt="" />
            <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/city-tour-3.png`} alt="" />
            <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/city-tour-4.png`} alt="" />
            <img className="rounded-lg" src={`${process.env.PUBLIC_URL}/images/city-tour-5.png`} alt="" />
          </>
        }
        incluye={
          <>
            <p>Transporte turistico</p>
            <p>Guia profesional</p>
            <p>Asistencia permanente</p>
            <p>Desayuno buffet</p>
            <p>Almuerzo buffet</p>
          </>
        }
        noIncluye={
          <>
            <p>Pago de ingreso</p>
            <p>Alquiler de caballo</p>
            <p>Gastos extra</p>
          </>
        }
        caracteristica={
          <>
            <CardCaracteristica
              icon={`${process.env.PUBLIC_URL}/icons/level.svg`}
              title={"Dificultad"}
              desc={"Dificil"}
            />
            <CardCaracteristica
              icon={`${process.env.PUBLIC_URL}/icons/compartido.svg`}
              title={"Tipo Servicio"}
              desc={"Compartido"}
            />
            <CardCaracteristica
              icon={`${process.env.PUBLIC_URL}/icons/duracion.svg`}
              title={"Duración"}
              desc={"Full day"}
            />
            <CardCaracteristica
              icon={`${process.env.PUBLIC_URL}/icons/cultural.svg`}
              title={"Tipo de Tour"}
              desc={"Trek"}
            />
          </>
        }
        lugarvisita={
          <>
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Cusipata"}
            />
            <CardLugar
              icon={"/icons/street-view-solid.svg"}
              title={"Vinicunca"}
            />
            
          </>
        }
        TourRecomienda={
          <>
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"MONTAÑA 7 COLORES"}
              puntuacion={"4.5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"VALLE VIP"}
              puntuacion={"5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"LAGUNA HUAMANTAY"}
              puntuacion={"5"}
            />
            <CardRecomienda
              img={"/images/laguna_huamantay.png"}
              title={"MARAS-MORAY"}
              puntuacion={"4.5"}
            />
          </>
        }
      />
    </>
  );
}
