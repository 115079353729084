import React from 'react';
export default function CardRecomienda({ img, title, puntuacion }) {
    return (
      <>
        <div class="relative flex w-full max-w-[26rem] flex-col rounded-xl bg-primary-200 bg-clip-border text-gray-700 shadow-lg">
                    <div class="relative mx-4 mt-4 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40">
                      <img
                        src={`${process.env.PUBLIC_URL}${img}`}
                        alt="ui/ux review check"
                      />
                      <div class="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60"></div>
                      <button
                        class="!absolute top-4 right-4 h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-full text-center align-middle font-sans text-xs font-medium uppercase text-red-50 transition-all hover:scale-125 active:text-red-500 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"
                        data-ripple-dark="true"
                      >
                        <span class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            aria-hidden="true"
                            class="h-6 w-6"
                          >
                            <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"></path>
                          </svg>
                        </span>
                      </button>
                    </div>
                    <div class="p-6 h-[80px]">
                      <div class="mb-3 flex items-center justify-around">
                        <h5 class="block font-sans lg:text-[14px] text-[10px] font-medium leading-snug tracking-normal text-blue-gray-900 antialiased">
                          {title}
                        </h5>
                        
                      </div>
                    </div>
                    <div class="p-2 pt-3">
                      <button
                        class="block w-full select-none rounded-lg bg-primary-800 py-3.5 px-1 text-center align-middle text-sm font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"
                        data-ripple-light="true"
                      >
                        Más informacion
                      </button>
                    </div>
                  </div>
      </>
    );
  }