import React from 'react';
export default function MisionAndVision() {
  return (
    <>
      <div className="my-8">
        <div className="mb-8">
          <h1 className="font-bold text-center text-[28px] md:text-[48px] text-primary-800">
            MISIÓN Y VISIÓN
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-12 md:gap-x-12">
          <div className="flex flex-col">
            <div className="bg-primary-800 py-4 flex items-center justify-center rounded-tl-3xl">
              <img
                className="h-10 lg:h-36 "
                src={`${process.env.PUBLIC_URL}${"/icons/mision.svg"}`}
                alt="mision"
              />
            </div>
            <div className="flex-1 bg-primary-100  p-4 flex flex-col items-center  rounded-br-3xl min-h-[176px]">
              <h1 className="text-[30px] font-bold text-primary-800">MISIÓN</h1>
              <p className="text-[14px] lg:text-[20px] font-normal text-justify">
                "Nuestra misión es crear viajes inolvidables para nuestros clientes de las cuales 
                ayuden a cumplir sus sueños de conocer nuestra maravilla peruana por lo que nuestros 
                servicios se realizan de una manera personalizada y un staff de profesionales 
                comprometidos con el trabajo que realizan con cada uno de nuestros clientes."
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="bg-primary-800 py-4 flex items-center justify-center rounded-tl-3xl">
              <img
                className="h-10 lg:h-36"
                src={`${process.env.PUBLIC_URL}${"/icons/vision.svg"}`}
                alt="vision"
              />
            </div>
            <div className="flex-1 bg-primary-100 p-4 flex flex-col items-center rounded-br-3xl min-h-[176px]">
              <h1 className="text-[30px] font-bold text-primary-800">VISIÓN</h1>
              <p className="text-[14px] lg:text-[20px] font-normal text-justify">
                "Nuestra Visión es brindar una calidad de servicio que satisfagan a nuestros clientes , 
                asistiéndolos con puntualidad , responsabilidad , brindando el mejor trato cordial y 
                respetuoso de igual manera cuidando el confort , seguridad y bienestar por lo que contamos 
                con personal capacitado en el rubro."
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
