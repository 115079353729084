import { useEffect,React } from "react";

import MisionAndVision from "./MisioAndVision";



export default function Nosotros() {
  useEffect(() => {
    document.title = `Donalds Nosotros`;
  }, []);
  return (
    <>
      <div className="relative">
        <img
          className={`pagadiario-container`}
          src={`${process.env.PUBLIC_URL}${"/images/nosotros.png"}`}
        />
        <div className="absolute w-full h-full top-0 ">
          <div className="w-full h-full l mx-auto max-w-7xl grid lg:content-center items-end px-4 lg:px-16">
            <h2 className="my-auto text-[24px] md:text-[32px] lg:text-[64px] font-bold text-primary-50">
              {"NOSOTROS"}
            </h2>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-full max-w-7xl mx-auto px-10 my-2 lg:my-16">

        <h1 className="font-bold text-center text-[20px] lg:text-[48px] text-primary-800 mb-2 lg:mb-8">
           NUESTRA HISTORIA
        </h1>
        <div className="lg:mb-16 mb-4">
            <div className="flex flex-col md:flex-row lg:flex-row w-full justify-center">
              
              <div className="w-full md:w-6/12 lg:w-6/12 py-2 items-center md:justify-center lg:justify-center">
                <p className="font-light text-justify text-[12px] lg:text-[16px] ">
                La agencia de viajes fue fundada en Cusco hace 5 años con el objetivo de ofrecer 
                experiencias turísticas auténticas y enriquecedoras en esta fascinante región peruana. 
                Desde sus inicios, la agencia se ha destacado por su pasión por el turismo responsable, 
                promoviendo el respeto por la cultura local, la conservación del medio ambiente y el 
                desarrollo sostenible de las comunidades.</p>
                <br />
                <p className="font-light text-justify text-[12px] lg:text-[16px]">
                A lo largo de estos 5 años, la agencia ha diseñado una amplia variedad de tours que 
                permiten a los viajeros explorar los impresionantes paisajes, la historia milenaria y la 
                rica herencia cultural de Cusco y sus alrededores. Gracias a un equipo de guías expertos 
                y comprometidos, la agencia ha logrado ofrecer experiencias únicas y memorables, adaptadas 
                a las preferencias y necesidades de cada cliente.</p>
                <br />
                <p className="font-light text-justify hidden lg:block">
                Con un enfoque en la calidad del servicio, la seguridad de los viajeros y el respeto por 
                la diversidad cultural, la agencia se ha ganado la confianza y el reconocimiento tanto de 
                los visitantes como de los colaboradores locales. Su trayectoria de 5 años en el rubro 
                turístico es testimonio de su dedicación y compromiso con la excelencia, posicionándola como 
                una de las principales opciones para quienes desean descubrir la magia de Cusco de manera 
                auténtica y responsable.</p>
                <br />
              </div>
              <div className="flex-1 flex items-center ml-8 justify-center">
                <img
                  className="w-full shadow-2xl"
                  src={`${process.env.PUBLIC_URL}/images/principios_donald.jpg`}
                  alt="historia"
                />
              </div>
            </div>
          </div>

          <MisionAndVision />

          <h1 className="font-bold text-center text-[20px] md:text-[48px] text-primary-800 mb-8">
           NUESTRO PROPOSITO
          </h1>
          <div className="mb-4">
            <div className="flex flex-col lg:flex-row">
              <div className="flex-1 flex items-center justify-center">
                <img
                  className="w-full shadow-2xl"
                  src={`${process.env.PUBLIC_URL}/images/proposito_donald.jpg`}
                  alt="proposito"
                />
              </div>
              <div className="w-full lg:w-6/12  p-4">
                <p className="font-light text-justify text-[12px] lg:text-[16px]">
                El propósito de nuestra agencia es proporcionar experiencias turísticas excepcionales 
                y significativas que permitan a los viajeros descubrir y apreciar la belleza, la 
                historia y la cultura de nuestro Cusco milenario y maravilloso.
                </p>
                <br />
                <ul className="font-light text-justify list-disc ml-8 text-[12px] lg:text-[16px]">
                  <li>Autenticidad cultural.</li>
                  <li>Sostenibilidad ambiental.</li>
                  <li>Innovación y calidad.</li>
                  <li>Responsabilidad social.</li>
                  <li>Educación y conciencia.</li>
                  <li>Experiencias memorables.</li>
                </ul>
                <br />
                <p className="font-light text-justify text-[12px] lg:text-[16px]">
                Donald´s Tour Cusco al centrarte en estos propósitos y demostrar un compromiso 
                genuino con la excelencia, la responsabilidad y la satisfacción del cliente, 
                podrás destacarte en el competitivo mercado turístico y atraer a más viajeros a 
                tu agencia de viajes en Cusco.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
