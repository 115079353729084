import React from 'react';

export default function CardCaracteristica({ icon, title, desc }) {
    return (
      <>
        <div class="inline-flex lg:h-full w-[200px]  items-center">
                      <div class="w-1/5 text-gray-800 font-bold mr-4">
                        <img
                          src={icon}
                          className="w-20 h-20 text-white"
                        />
                      </div>
                      <div class="w-[120px]  font-bold  ">
                        <div className="inline-grid grid-cols-1 text-white">
                          <span>{title}</span>
                          <span className="font-light">{desc}</span>
                        </div>
                      </div>
                    </div>
      </>
    );
  }