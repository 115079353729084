import { Link } from "react-router-dom";
import { useState, React } from "react";


export default function ContainerItems({ icons,pack }) {

  return (
    <>
      <div className={`w-full items-center h-24 grid lg:grid-cols-4 px-8 pt-4 gap-4`}>
        {icons.map((icon, index) =>
          icon.title === "Beneficios" ? (
            <div
              className="flex-1 h-full flex flex-col items-center justify-center cursor-pointer mt-8"
              key={`icon ${index + 1}`}
              preventScrollReset={true}
            >
              {icon.src}
              <p className="text-[14px] text-primary-900 font-medium mt-2 ">{icon.title}</p>
            </div>
          ) : (
            <>
              <div
              className="flex-1 h-full flex flex-col items-center  cursor-pointer"
              key={`icon ${index + 1}`}
              preventScrollReset={true}
            >
              <div className="w-full">
                <div className=" p-2 border-b-2 border-b-primary-800 content-center">
                {icon.src}
                <p className="text-[18px] text-primary-800 font-bold mt-2 text-center ">{icon.title}</p>
                </div>
                <div className="mt-4 block ">
                  <div className="mb-1">
                    <Link to={icon.linktour1} preventScrollReset={true}>
                        <p className={"ml-2 text-primary-800 w-full font-normal text-[14px] hover:font-semibold"}>
                        {icon.tour1}
                        </p>
                    </Link>
                  </div>
                  <div className="mb-1">
                    <Link to={icon.linktour2} preventScrollReset={true}>
                        <p className={"ml-2 text-primary-800 w-full font-normal text-[14px] hover:font-semibold"}>
                        {icon.tour2}
                        </p>
                    </Link>
                  </div>
                  <div className="mb-1">
                    <Link to={icon.linktour3} preventScrollReset={true}>
                        <p className={"ml-2 text-primary-800 w-full font-normal text-[14px] hover:font-semibold"}>
                        {icon.tour3}
                        </p>
                    </Link>
                  </div>
                  <div className="mb-1">
                    <Link to={icon.linktour4} preventScrollReset={true}>
                        <p className={"ml-2 text-primary-800 w-full font-normal text-[14px] hover:font-semibold"}>
                        {icon.tour4}
                        </p>
                    </Link>
                  </div>
                  <div className="mb-1">
                    <Link to={icon.linktour5} preventScrollReset={true}>
                        <p className={"ml-2 text-primary-800 w-full font-normal text-[14px] hover:font-semibold"}>
                        {icon.tour5}
                        </p>
                    </Link>
                  </div>
                  
                </div>
              </div>              
            </div>
            </>
          )
        )}
      </div>
    </>
  );
}
