import React from 'react';

export default function CardLugar({ icon, title }) {
    return (
      <>
        <div class="inline-flex mb-4">
            <span class="w-1/5 bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-l border-primary-800 border-solid border-2 ">
            <img
                      src={`${process.env.PUBLIC_URL}${icon}`}
            className="w-5 h-5"/>
            </span>
            <span class="w-4/5 bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-r border-primary-800 border-solid border-y-2 border-r-2">
                      {title}
            </span>
        </div>
      </>
    );
  }